<template>
  <div class="mt-2 pm-container card p-4">
    <div>
      <router-link class="btn btn-primary" to="/account">Zurück</router-link>
    </div>

    <hr>
    <h4>Neuer Kunde</h4>


    <div class="row mb-3">
      <label for="inputEmail3" class="col-sm-2 col-form-label">Name und Vorname</label>
      <div class="col-sm-10">
        <input v-model="name" type="email" class="form-control" id="inputEmail3" placeholder="Ruth Etti">
      </div>
    </div>

    <div class="row mb-3">
      <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
      <div class="col-sm-10">
        <input v-model="email" type="email" class="form-control" placeholder="ruthetti@web.de">
      </div>
    </div>

    <div class="row mb-3">
      <label for="inputEmail3" class="col-sm-2 col-form-label">Password (optional)</label>
      <div class="col-sm-10">
        <input v-model="password" type="email" class="form-control" placeholder="12345">
      </div>
    </div>

    <fieldset class="row mb-3">
      <legend class="col-form-label col-sm-2 pt-0"></legend>
      <div class="col-sm-10">
        <div class="form-check">
          <input v-model="sendInvitationMail" class="form-check-input" type="checkbox" name="gridRadios"
                 id="gridRadios1" value="option1">
          <label class="form-check-label" for="gridRadios1">
            Einladungs-Mail schicken
          </label>
        </div>
        <div class="form-check">
          <input v-model="isAdmin" class="form-check-input" type="checkbox" name="gridRadios" id="gridRadios3"
                 value="option1">
          <label class="form-check-label" for="gridRadios1">
            Ist Administrator
          </label>
        </div>
      </div>
    </fieldset>

    <div>
      <button @click="addAccount" class="btn btn-success">Kunde anlegen</button>
    </div>

  </div>
</template>

<script>
export default {
  name: "AccountNew",

  data() {
    return {
      name: "",
      email: "",
      password: null,
      invitationLink: "",
      sendInvitationMail: true,
      newsletter: true,
      isAdmin: false
    }
  },

  methods: {
    addAccount() {
      let account = {
        name: this.name,
        email: this.email,
        password: this.password,
        sendInvitationMail: this.sendInvitationMail,
        newsletter: this.newsletter,
        isAdmin: this.isAdmin
      }

      this.$store.dispatch("account/add", account)
          .then(() => alert("Account angelegt!"))
          .catch(() => alert("Fehler beim Anlegen"));
    }
  }
}
</script>

<style scoped>

</style>